<template>
  <en-result title="Stomach health risk assessment results" :content="content" :dist="dist" :fot="fot" :id="id" :img-show="imgShow"></en-result>
</template>

<script>
export default {
  name:'',
  data(){
    return {
      id: '',
      content: '',
      dist: '',
      fot: '',
      imgShow: true
    }
  },
  mounted(){
    this.id = this.$route.query.id;
    var isCheck = this.$route.query.isCheck;
    var imgShow = this.$route.query.img;
    if(imgShow == 'true'){
      this.imgShow = true;
    }else{
      this.imgShow = false;
    }
    if(isCheck == 'true'){
      this.content = 'You <span style="color: #FF6054">belong to</span> the target population for gastric cancer screening in my country';
      this.dist = 'But also pay attention to daily gastric health monitoring and regular physical examinations';
    }else if(isCheck == 'false'){
      this.content = 'You <span style="color: #1ED6A8">do not belong</span> to the target population for gastric cancer screening in my country';
      this.dist = 'It is recommended to seek medical treatment and perform gastroscopy in accordance with the doctor’s advice';
    }else{
      var str = isCheck == 'Low risk' ? 'Gastroscopy is not recommended temporarily' : isCheck == 'Medium risk' ? 'Gastroscopy recommended' : 'Gastroscopy is strongly recommended';
      this.content = `Based on the information you fill in${str}`;
      this.dist = 'Please seek medical attention in time and follow the doctor’s advice for gastroscopy';
      this.fot = 'Independent research and development, the results are for reference only, subject to the doctor’s diagnosis results'
    }
  },
  methods:{

  }
}
</script>

<style scoped lang="scss">
  
</style>
